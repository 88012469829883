<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab-item>
        <div class="form-subheader">Сведения</div>
        <b-form-group id="input-group-1" label="OID" label-for="input-1">
          <b-form-input
            id="input-1"
            :value="form.oid"
            placeholder="OID"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Дилер" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.dealer"
            placeholder="Дилер"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-3" label="Регион" label-for="input-3">
          <b-form-input
            id="input-3"
            v-model="form.territory"
            placeholder="Регион"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="Используется"
          label-for="input-4"
        >
          <b-form-input
            id="input-4"
            :value="form.is_used | booleanYesNo"
            placeholder="Используется"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-5" label="Мото" label-for="input-5">
          <b-form-input
            id="input-5"
            :value="form.is_moto | booleanYesNo"
            placeholder="Мото"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-6" label="Ком. транс" label-for="input-6">
          <b-form-input
            id="input-6"
            :value="form.is_commercial_transport | booleanYesNo"
            placeholder="Ком. транс"
            disabled
          ></b-form-input>
        </b-form-group>

        <div class="form-subheader">Редактируемые</div>
        <b-form>
          <b-form-group id="input-group-7">
            <switch-checkbox
              v-model="form.is_salon_for_web"
              label="Это салон для сайта"
            />
          </b-form-group>

          <b-form-group
            id="input-group-7"
            label="Салон наименование"
            label-for="input-7"
          >
            <b-form-input
              id="input-7"
              v-model="form.salon.name"
              placeholder="Салон наименование"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-8" label="Адрес" label-for="input-8">
            <b-form-input
              id="input-8"
              v-model="form.address"
              placeholder="Адрес"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-9" label="Метро" label-for="input-9">
            <b-form-input
              id="input-9"
              v-model="form.metro"
              placeholder="Метро"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-10"
            label="Долгота"
            label-for="input-10"
          >
            <b-form-input
              id="input-10"
              v-model="form.coords.lat"
              required
              placeholder="Долгота"
              type="number"
              step="any"
              max="1000000"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-11" label="Широта" label-for="input-11">
            <b-form-input
              id="input-11"
              v-model="form.coords.lng"
              placeholder="Широта"
              type="number"
              step="any"
              max="1000000"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-12"
            label="Открыто"
            label-for="input-12"
          >
            <b-form-timepicker
              class="mb-2"
              v-model="form.timework.open"
              :show-seconds="false"
              no-close-button
              id="12"
            />
          </b-form-group>

          <b-form-group
            id="input-group-13"
            label="Закрыто"
            label-for="input-13"
          >
            <b-form-timepicker
              class="mb-2"
              v-model="form.timework.close"
              :show-seconds="false"
              no-close-button
              id="13"
            />
          </b-form-group>

          <b-form-group id="input-group-14" label="Сайт" label-for="input-14">
            <b-form-input
              id="input-14"
              v-model="form.homepage"
              placeholder="Сайт"
              type="url"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-15"
            label="Салон телефон для мобильного"
            label-for="input-15"
          >
            <b-form-input
              id="input-15"
              v-model="form.phones.main_mobile"
              placeholder="Салон телефон для мобильного"
              type="tel"
              v-mask="'+7(###) ###-##-##'"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-16"
            label="Телефон"
            label-for="input-16"
          >
            <b-form-input
              id="input-16"
              v-model="form.phones.main"
              placeholder="Телефон"
              type="tel"
              v-mask="'+7(###) ###-##-##'"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-17"
            label="Телефон для Avito"
            label-for="input-17"
          >
            <b-form-input
              id="input-17"
              v-model="form.phones.avito"
              placeholder="Телефон для Avito"
              type="tel"
              v-mask="'+7(###) ###-##-##'"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-18"
            label="Слесарный сервис"
            label-for="input-18"
          >
            <b-form-select
              v-model="form.locksmith_service"
              :options="locksmithServicesOptions"
              id="input-18"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-19"
            label="Кузвной цех"
            label-for="input-19"
          >
            <b-form-select
              v-model="form.body_shop"
              :options="bodyShopOptions"
              id="input-19"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-20"
            label="Салон услуги"
            label-for="input-20"
          >
            <b-form-textarea
              id="input-20"
              :rows="5"
              size="lg"
              placeholder="Салон услуги"
            />
          </b-form-group>

          <b-form-group
            id="input-group-21"
            label="Салон преимущества"
            label-for="input-21"
          >
            <b-form-textarea
              id="input-21"
              :rows="5"
              size="lg"
              placeholder="Салон преимущества"
            />
          </b-form-group>

          <b-form-group
            id="input-group-22"
            label="Салон фото"
            label-for="input-22"
          >
            <upload-file
              id="inpit-22d"
              v-model="form.salon.photos"
              :options="photosOptions"
              multiple
            />
          </b-form-group>

          <b-form-group
            id="input-group-23"
            label="Салон лого белый"
            label-for="input-23"
          >
            <upload-file
              id="inpit-23"
              v-model="form.salon.logo_white"
              :options="logoOptions"
            />
          </b-form-group>

          <b-form-group
            id="input-group-24"
            label="Салон лого чёрный"
            label-for="input-24"
          >
            <upload-file
              id="inpit-24"
              v-model="form.salon.logo_black"
              :options="logoOptions"
            />
          </b-form-group>

          {{ files }}
          <b-form-group
            id="input-group-24"
            label="Для теста мулитигагрузки потом удалить"
            label-for="input-24"
          >
            <file-drop-multiple v-model="files" />
          </b-form-group>
        </b-form>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import SwitchCheckbox from '../../content/SwitchCheckbox';
import UploadFile from '@/view/content/widgets/FileDrop';
import FileDropMultiple from '@/view/content/widgets/FileDropMultiple/FileDropMultiple.vue';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { email, required, minLength } from 'vuelidate/lib/validators';

import { mask } from 'vue-the-mask';

export default {
  components: { UploadFile, SwitchCheckbox, FileDropMultiple },

  mixins: [validationMixin],

  directives: { mask },

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
      photosOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        maxFilesize: 20,
        acceptedFiles: 'image/*',
      },
      logoOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        maxFilesize: 20,
        maxFiles: 1,
        acceptedFiles: 'image/*',
      },

      files: [
        {
          guid: 'afc860b3-a843-48e6-bc4d-02b7cbe8e1b1',
          preview:
            'https://dev-files.ma.ru/MobMA/afc860b3-a843-48e6-bc4d-02b7cbe8e1b7/thumbnail/750',
          type: 'image/png',
          url: 'https://dev-files.ma.ru/MobMA/afc860b3-a843-48e6-bc4d-02b7cbe8e1b7/Download',
        },

        {
          guid: 'afc860b3-a843-48e6-bc4d-02b7cbe8e1b2',
          preview:
            'https://dev-files.ma.ru/MobMA/afc860b3-a843-48e6-bc4d-02b7cbe8e1b7/thumbnail/750',
          type: 'image/png',
          url: 'https://dev-files.ma.ru/MobMA/afc860b3-a843-48e6-bc4d-02b7cbe8e1b7/Download',
        },
      ],
    };
  },

  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },

  computed: {
    ...mapGetters(['locksmithServices', 'bodyShops']),
    bodyShopOptions() {
      return this.bodyShops.map((x) => {
        return { text: x.title, value: x.oid };
      });
    },

    locksmithServicesOptions() {
      return this.locksmithServices.map((x) => {
        return { text: x.title, value: x.oid };
      });
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    submit() {
      this.$v.form.$touch();
      /* if (this.$v.form.$anyError) {
        submitButton[0].classList.remove(
          'spinner',
          'spinner-light',
          'spinner-right'
        );
        return;
      } */

      Swal.fire({
        title: '',
        text: 'Сохранено!',
        icon: 'success',
        heightAuto: false,
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
