<template>
  <div
    :style="isDragging && 'border-color: green;'"
    class="dropzone"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
    @click.stop="$refs.files.click()"
  >
    <div v-show="loading" class="loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden" />
        </div>
      </div>
    </div>

    <div v-show="files?.length === 0" class="label-upload">
      <label ref="uploadLabel" class="file-label">
        <div v-if="isDragging">Для загрузки отпустите файл</div>
        <div v-else>
          Перетащите файлы сюда или щёлкните на поле, чтобы загрузить.
        </div>
      </label>
    </div>

    <div
      v-show="files?.length > 0 && loading === false"
      class="files-container"
    >
      <file-drop-item
        v-for="file in files"
        :file="file"
        :key="`file-guid-${file.guid}`"
        @remove-file="removeFile"
      />
    </div>

    <input
      ref="files"
      :multiple="true"
      type="file"
      id="fileInput"
      class="hidden-input"
      @input="onChange"
      accept=".jpg, .jpeg, .png"
    />
  </div>
</template>

<script>
// import JwtService from '@/core/services/jwt.service';
import {
  GET_UPLOAD_TOKEN,
  UPLOAD_SINGLE_FILE,
} from '@/core/services/store/uploads.module';

import FileDropItem from './FileDropItem';

export default {
  components: { FileDropItem },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => null,
    },
    id: {
      type: String,
    },
  },

  data() {
    return {
      isDragging: false,
      localValue: this.value,
      files: this.value,
      preview: null,
      loading: false,
      guidsPreviews: [],
    };
  },

  beforeMount() {
    this.$store.dispatch(GET_UPLOAD_TOKEN);

    if (this.value?.preview) {
      this.preview = this.value.preview;
    }
  },

  methods: {
    async onChange() {
      this.loading = true;

      [...this.$refs.files.files].forEach(async (file) => {
        const content = await this.getBase64(file);

        const data = {
          meta: {
            extension: /[.]/.exec(file.name)
              ? '.' + /[^.]+$/.exec(file.name)?.[0]
              : undefined,
            'content-type': file.type,
          },
          content: content.split(',')?.[1],
        };

        const result = await this.$store.dispatch(UPLOAD_SINGLE_FILE, data);

        if (result) {
          const formatedResult = {
            guid: result.id,
            type: result.meta['Content-Type'],
            ext: result.meta.extension,
            preview: content,
          };

          this.files.push(formatedResult);
        }
      });

      this.$emit('input', this.files);
      this.loading = false;
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.$refs.files.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },

    async getBase64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
      });
      return result_base64;
    },

    removeFile(guid) {
      const idx = this.files.findIndex((x) => x.guid === guid);

      if (idx !== -1) {
        this.files.splice(idx, 1);
        this.$emit('input', this.files);
      }
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  padding: 20px;
  border: 2px solid #e5e5e5;
  font-family: Arial, sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  background-color: #f6f6f6;
  min-height: 150px;
  height: auto;

  .label-upload {
    text-align: center;
  }

  .hidden-input {
    display: none;
  }

  .preview-image {
    min-width: 150px;
    .image-item {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 150px;
      max-width: 150px;
      align-items: center;
      flex-grow: 0;

      img {
        position: absolute;
        z-index: 1;
        margin: 0 auto;
        display: block;
      }

      .delete-button {
        position: absolute;
        top: auto;
        bottom: 5px;
        margin: 0 auto;
        z-index: 2;
      }
    }
  }

  .files-container {
    display: flex;
  }
}
</style>
