<template>
  <div v-show="file !== null || preview !== null" class="preview-image">
    <div class="image-item">
      <img :src="preview" style="max-width: 150px" />
      <button
        type="button"
        class="btn btn-outline-danger delete-button"
        @click.stop="$emit('remove-file', file.guid)"
      >
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      deafult: () => null,
    },

    manualPreview: {
      type: String,
      default: () => null,
    },
  },

  computed: {
    preview() {
      return this.file?.preview ? this.file.preview : this.manualPreview;
    },
  },
};
</script>

<style lang="scss"></style>
